import React, { useState } from "react";

const containerStyle = {
  display: "flex",
  alignItems: "center",
  gap: "10px",
};

const starStyle = {
  color: "yellow",
  fontSize: "25px",
  cursor: "pointer",
};

const textStyle = {
  lineHeight: "0",
  margin: "0",
};

function Star({ onRate, full, onHoverIn, onHoverOut, width }) {
  const imagePath = "/star_1.png";
  const imagePath2 = "/star_2.png";
  const image = {
    width: `${width}px`,
    cursor: "pointer",
  };
  return (
    <>
      {full ? (
        <img
          src={process.env.PUBLIC_URL + imagePath2}
          onClick={onRate}
          style={image}
          alt="Deskripsi Gambar"
          onMouseEnter={onHoverIn}
          onMouseLeave={onHoverOut}
        />
      ) : (
        <img
          src={process.env.PUBLIC_URL + imagePath}
          onClick={onRate}
          style={image}
          alt="Deskripsi Gambar"
          onMouseEnter={onHoverIn}
          onMouseLeave={onHoverOut}
        />
      )}
    </>
  );
}

function StarRating({ max = 5, width = 25, onSetRating }) {
  const [rating, setRating] = useState(0);
  const [tempRating, setTempRating] = useState(0);
  function handleRating(rating) {
    setRating(rating);
    onSetRating(rating);
  }
  return (
    <div style={containerStyle}>
      <div className={starStyle}>
        {Array.from({ length: max }, (_, i) => (
          <Star
            key={i}
            onRate={() => handleRating(i + 1)}
            full={tempRating ? tempRating >= i + 1 : rating >= i + 1}
            onHoverIn={() => setTempRating(i + 1)}
            onHoverOut={() => setTempRating(0)}
            width={width}
          />
        ))}
      </div>
      <p style={textStyle}>{tempRating ? tempRating : rating}</p>
    </div>
  );
}

export default StarRating;
/*
  FULL STAR
  
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="#000"
    stroke="#000"
  >
    <path
      d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
    />
  </svg>
  
  
  EMPTY STAR
  
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="#000"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="{2}"
      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
    />
  </svg>
  
  */
