import { useEffect, useState } from "react";
import StarRating from "./StarRating";

const average = (arr) =>
  arr.reduce((acc, cur, i, arr) => acc + cur / arr.length, 0);
function Logo() {
  return (
    <div className="logo">
      <span role="img">🎫</span>
      <h1>Movie</h1>
    </div>
  );
}
// function NumResults({ movies }) {
//   return (
//     <p className="num-results">
//       Found <span>{movies.length}</span> results
//     </p>
//   );
// }
function Search({ handleSearch }) {
  const [searchTerm, setSearchTerm] = useState("");
  function handleSubmit(e) {
    e.preventDefault();
    handleSearch(searchTerm);
    setSearchTerm("");
  }

  return (
    <form onSubmit={handleSubmit} style={{ display: "flex" }}>
      <input
        className="search"
        type="text"
        placeholder="Cari film..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <button
        onClick={handleSearch}
        type="submit"
        style={{
          backgroundColor: "#134a77",
          color: "#fff",
          padding: " 3px 10px",
          cursor: "pointer",
          borderRadius: "3px",
        }}
      >
        Cari
      </button>
    </form>
  );
}

function Navbar({ children }) {
  return <nav className="nav-bar">{children}</nav>;
}
// Navbar_end

// Main
function BoxMovies({ children }) {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div className="box">
      <button className="btn-toggle" onClick={() => setIsOpen((open) => !open)}>
        {isOpen ? "–" : "+"}
      </button>
      {isOpen && children}
    </div>
  );
}
function MovieDetails({ selectedId, onCloseMovie, onAddWatch, watched }) {
  const [movies, setMovies] = useState({});
  const [isloading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const isWatched = watched.some((movie) => movie.imdbID === selectedId);

  useEffect(() => {
    async function fecthMovie() {
      try {
        setLoading(true);
        const res = await fetch(
          `http://www.omdbapi.com/?apikey=${API_KEY}&i=${selectedId}`
        );

        if (!res.ok) throw new Error("Something Went Wrong");
        const data = await res.json();
        if (data.Response === "False") throw new Error(data.Error);

        setMovies(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }
    fecthMovie();
  }, [selectedId]);

  return (
    <div className="details">
      {isloading && <Loader />}
      {error && <ErrorMessage message={error} />}
      {!isloading && !error && (
        <DetailItem
          details={movies}
          selectedId={selectedId}
          onCloseMovie={onCloseMovie}
          onAddWatch={onAddWatch}
          watched={watched}
          isWatched={isWatched}
        />
      )}
    </div>
  );
}

function DetailItem({
  details,
  onCloseMovie,
  selectedId,
  onAddWatch,
  watched,
  isWatched,
}) {
  const [userRating, setUserRating] = useState(0);
  const {
    Title: title,
    Year: year,
    Poster: poster,
    imdbRating,
    Runtime: runtime,
    Plot: plot,
    Genre: genre,
    Actors: actors,
    Director: director,
    Released: released,
  } = details;

  function handleAddWatched() {
    const newWatchedMovie = {
      imdbID: selectedId,
      title,
      year,
      poster,
      imdbRating: Number(imdbRating),
      runtime: Number(runtime.split(" ").at(0)),
      userRating: Number(userRating),
    };

    onAddWatch(newWatchedMovie);
    onCloseMovie();
  }

  const userRate = watched.find(
    (movie) => movie.imdbID === selectedId
  )?.userRating;

  useEffect(() => {
    if (!title) return;
    document.title = `PopMovie | ${title}`;

    return function () {
      document.title = "PopMovie";
    };
  }, [title]);

  return (
    <>
      <header>
        <button className="btn-back" onClick={onCloseMovie}>
          ❌
        </button>
        <img src={poster} alt={`${title} poster`} />
        <div className="details-overview">
          <h2>{title}</h2>
          <p>
            <span>⭐</span>
            <span>{imdbRating}</span>
          </p>
          <p>
            <span>⏳</span>
            <span>{runtime}</span>
          </p>
          <p>
            <span>📅</span>
            <span>{released}</span>
          </p>
        </div>
      </header>
      <section>
        <p>
          <em>{plot}</em>
        </p>
        <p>Genre : {genre}</p>
        <p>Starring : {actors}</p>
        <p>Directed By: {director}</p>
        <div className="rating">
          {!isWatched ? (
            <>
              <StarRating max={10} onSetRating={setUserRating} />
              {userRating > 0 && (
                <button className="btn-add" onClick={handleAddWatched}>
                  + add to Watched
                </button>
              )}
            </>
          ) : (
            <p>
              You Have Watched This Movie with a rating of ⭐ {userRate}/10{" "}
            </p>
          )}
        </div>
      </section>
    </>
  );
}

function MovieList({ movies, onSelectMovieId }) {
  return (
    <ul className="list list-movies">
      {movies?.map((movie, index) => (
        <MovieItem
          movie={movie}
          key={index}
          onSelectMovieId={onSelectMovieId}
        />
      ))}
    </ul>
  );
}
function MovieItem({ movie, onSelectMovieId }) {
  return (
    <li onClick={() => onSelectMovieId(movie.imdbID)}>
      <img src={movie.Poster} alt={`${movie.Title} poster`} />
      <h3>{movie.Title}</h3>
      <div>
        <p>
          <span>📅</span>
          <span>{movie.Year}</span>
        </p>
      </div>
    </li>
  );
}

function WatchedSummary({ watched }) {
  const avgImdbRating = average(watched.map((movie) => movie.imdbRating));
  const avgUserRating = average(watched.map((movie) => movie.userRating));
  const avgRuntime = average(watched.map((movie) => movie.runtime));
  return (
    <div className="summary">
      <h2>Movies you watched</h2>
      <div>
        <p>
          <span>#️⃣</span>
          <span>{watched.length} movies</span>
        </p>
        <p>
          <span>🎬</span>
          <span>{avgImdbRating.toFixed(1)}</span>
        </p>
        <p>
          <span>🌟</span>
          <span>{avgUserRating.toFixed(1)}</span>
        </p>
        <p>
          <span>⏳</span>
          <span>{Math.trunc(avgRuntime)} min</span>
        </p>
      </div>
    </div>
  );
}

function WatchedItem({ movie, onDeleteWatched }) {
  return (
    <li key={movie.imdbID}>
      <img src={movie.poster} alt={`${movie.title} poster`} />
      <h3>{movie.Title}</h3>
      <div>
        <p>
          <span>🎬</span>
          <span>{movie.imdbRating}</span>
        </p>
        <p>
          <span>🌟</span>
          <span>{movie.userRating}</span>
        </p>
        <p>
          <span>⏳</span>
          <span>{movie.runtime} min</span>
        </p>
        <button
          className="btn-delete"
          onClick={() => onDeleteWatched(movie.imdbID)}
        >
          x
        </button>
      </div>
    </li>
  );
}
function WatchedList({ watched, onDeleteWatched }) {
  return (
    <ul className="list">
      {watched.map((movie, index) => (
        <WatchedItem
          key={index}
          movie={movie}
          onDeleteWatched={onDeleteWatched}
        />
      ))}
    </ul>
  );
}
function Main({ children }) {
  return <main className="main">{children}</main>;
}

function Loader() {
  return (
    <div className="loader">
      <div className="loading-bar">
        <div className="bar"></div>
      </div>
    </div>
  );
}

function ErrorMessage({ message }) {
  return (
    <div className="error">
      <span>❌</span>
      {message}
    </div>
  );
}
const API_KEY = "6dfaee36";

export default function App() {
  const [movies, setMovies] = useState([]);
  const [watched, setWatched] = useState([]);
  const [isloading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [query, setQuery] = useState("harry");

  const [selectedMovieId, setSelectedMovieId] = useState(null);

  function handleSelectMovieId(id) {
    setSelectedMovieId((nilaiSebelum) => (nilaiSebelum === id ? null : id));
  }

  function handleCloseMovieId() {
    setSelectedMovieId(null);
  }

  useEffect(() => {
    async function fecthMovie() {
      try {
        setLoading(true);
        // setError("");
        const res = await fetch(
          `http://www.omdbapi.com/?s=${query}&apikey=${API_KEY}`
        );

        if (!res.ok) throw new Error("Something Went Wrong");
        const data = await res.json();
        if (data.Response === "False") throw new Error(data.Error);

        setMovies(data.Search);
        setError("");
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }
    // if (query.length < 3) {
    //   setMovies([]);
    //   setError("");
    //   return;
    // }

    fecthMovie();
  }, [query]);

  const handleSearch = (data) => {
    setQuery(data);
  };

  function handleAdWatched(movie) {
    setWatched((watched) => [...watched, movie]);
  }

  function handleDeleteWatched(id) {
    setWatched((watched) => watched.filter((movie) => movie.imdbID !== id));
  }

  return (
    <>
      {/* mengirim component dengan children */}
      <Navbar>
        <Logo />
        <Search handleSearch={handleSearch} />
        {/* <NumResults movies={movies} /> */}
      </Navbar>

      {/* mengirim component dengan element (prop) */}
      <Main>
        <div className="boxSearch">
          <BoxMovies>
            {isloading && <Loader />}
            {error && <ErrorMessage message={error} />}
            {!isloading && !error && (
              <MovieList
                movies={movies}
                onSelectMovieId={handleSelectMovieId}
              />
            )}
          </BoxMovies>
        </div>
        <BoxMovies>
          {selectedMovieId ? (
            <MovieDetails
              selectedId={selectedMovieId}
              onCloseMovie={handleCloseMovieId}
              onAddWatch={handleAdWatched}
              watched={watched}
            />
          ) : (
            <>
              <WatchedSummary watched={watched} />
              <WatchedList
                watched={watched}
                onDeleteWatched={handleDeleteWatched}
              />
            </>
          )}
        </BoxMovies>
      </Main>
      {/* <StarRating max={5} width={100} />
      <StarRating max={20} /> */}
    </>
  );
}
